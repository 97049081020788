import React, { useState, useEffect, useContext } from 'react';
import { ChevronDown, Menu, X } from 'lucide-react';
import styles from '../css/Header.css';
import { ThemeContext } from './ThemeContext'; // Assurez-vous d'importer correctement ThemeContext
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname + location.hash; // Combine pathname et hash pour obtenir l'URL complète avec #
  const { theme } = useContext(ThemeContext); // Utilisation du thème
  const [isVisible, setIsVisible] = useState({
    hero: false,
    features: false,
    about: false,
    testimonials: false,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setScrollY(scrollPosition);
      setIsScrolled(scrollPosition > 50);
      setIsVisible({
        hero: scrollPosition > 2,
        features: scrollPosition > 200,
        about: scrollPosition > 900,
        testimonials: scrollPosition > 1200,
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const imageOpacity = Math.max(0, 1 - scrollY / 350);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const headerBackground = scrollY > 350 ? theme.container : 'transparent';

  return (
    <div className="organic-store">
<header
       className={`header ${scrollY > 350 ? 'header-scrolled' : ''}`}
       style={{ background: headerBackground }}
     >
           <div className="header-container">
          {/* Bouton Menu Burger */}
          <button 
  onClick={toggleMenu}
  className={`burger-button ${isScrolled ? 'scrolled' : ''} ${scrollY > 10 && scrollY < 350 ? 'hidden' : ''}`}
>
  {isMenuOpen ? <X size={24} className="menu-icon" /> : <Menu size={24} className="menu-icon" />}
</button>

          {/* Logo */}
          <div className="logo-container">
  <img 
    src="https://api.epiceriebellis.fr/siteweb.bellis/assets/logo-blanc.png"
    alt="Belli's Épicerie" 
    className={`logo ${scrollY > 50 ? 'hidden' : ''}`}
  />
</div>
          
<div className="logo-container">
  {scrollY > 350 && (
    <img 
      src="https://api.epiceriebellis.fr/siteweb.bellis/assets/logo-vert.png" 
      alt="Belli's Épicerie" 
      className={`logo ${scrollY > 350 ? 'logo-scrolled' : ''}`}
    />
  )}
</div>
          {/* Icône Utilisateur */}
          {scrollY > 350 && (
            <div className="user-icon bg-green-800">
              <img 
                src="https://api.epiceriebellis.fr/siteweb.bellis/assets/pro.png" 
                alt="Belli's Épicerie" 
                style={{ width: '40px', height: '40px' }}
              />
            </div>
          )}
        </div>

        <nav className={`mobile-menu ${isMenuOpen ? 'open' : 'closed'} ${isScrolled ? 'scrolled' : ''}`} style={{ background: theme.container }}>
        <ul className="menu-list">
        {currentPath !== '/' && (
        <li className="menu-item">
          <a href="/" className="menu-link" style={{ color: theme.title }}>
            Home
          </a>
        </li>
      )}
      {currentPath !== '/pros' && (
        <li className="menu-item">
          <a href="/#/pros" className="menu-link" style={{ color: theme.title }}>
            Pros
          </a>
        </li>
      )}
      {currentPath !== '/DownloadAppPage' && (
        <li className="menu-item">
          <a href="/#/DownloadAppPage" className="menu-link" style={{ color: theme.title }}>
            Inscription beta
          </a>
        </li>
      )}
      {currentPath !== '/MyBellisProgram' && (
        <li className="menu-item">
          <a href="/#/MyBellisProgram" className="menu-link" style={{ color: theme.title }}>
            My Belli's
          </a>
        </li>
      )}
      {currentPath !== '/contact' && (
      <li className="menu-item">
      <a href="/#/contact" className="menu-link" style={{ color: theme.title }}>
        Contact
      </a>
        </li>
      )}
      {currentPath !== '#notre-engagement' && (
        <li className="menu-item">
          <a
            href="#notre-engagement"
            className="menu-link"
            style={{ color: theme.title }}
            onClick={() => {
              document.getElementById('notre-engagement').scrollIntoView({ behavior: 'smooth' });
            }}
          >
            Notre engagement
          </a>
        </li>
      )}
    </ul>
        </nav>
      </header>

      {/* Section Hero */}
      <section className="hero">
        <div className="hero-image-container" style={{ opacity: imageOpacity }}>
          <img 
            src="https://api.epiceriebellis.fr/siteweb.bellis/assets/images-accueil.png" 
            className="hero-image"
            alt="Belli's Hero"
          />
          <div className="hero-overlay"></div>
        </div>
        
      
  
        <ChevronDown className="scroll-indicator" size={32} color="#fff" />
      </section>

    </div>
  );
};

export default Header;
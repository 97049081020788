import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from './ThemeContext'; // Assurez-vous d'importer correctement ThemeContext

const SplashScreen = ({ onComplete }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { theme } = useContext(ThemeContext); // Utilisation du thème

  useEffect(() => {
    const timer = setTimeout(() => {
      if (onComplete) {
        onComplete();
      }
    }, 3000); // 3 secondes d'exemple

    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <div className="splash-container" style={{ background: theme.background }}>
      <div className="image-container">
        <img
          src="https://api.epiceriebellis.fr/siteweb.bellis/assets/logo/logo-splas.gif"
          alt="Logo"
          className="image"
          onLoad={() => setImageLoaded(true)}
        />
        {!imageLoaded && (
          <div className="loading-text">
              
          </div>
        )}
      </div>
      <style jsx>{`
        .splash-container {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 100vh;
        }
        .image-container {
          text-align: center;
        }
        .image {
          max-width: 250px;
          height: auto;
          margin: 0 auto;
        }
        .loading-text {
          margin-top: 1rem;
          color: #4A5568; /* Equivalent à text-gray-600 */
        }
            @media (max-width: 768px) {
     .image {
          max-width: 180px;
          height: auto;
          margin: 0 auto;
        }
      }
      `}</style>
    </div>
  );
};

export default SplashScreen;
import React, { useState, useContext } from "react";
import { ThemeContext } from './ThemeContext';

export default function EnhancedOrganicStore() {
  const [isVisible, setIsVisible] = useState({
    hero: false,
    features: false,
    about: false,
    testimonials: false,
  });
  const { theme } = useContext(ThemeContext); // Utilisation du thème
  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    {
      title: "BELLI'S",
      links: [
        { text: "Nos engagements", href: "#" },
        { text: "My Belli's", href: "/#/MyBellisProgram" },
        { text: "Devenir partenaire (pro)", href: "#" },
      ],
    },
    {
      title: "SERVICE CLIENT",
      links: [
        { text: "Nous contacter", href: "/#/contact" },
        { text: "Mon compte", href: "bellis://Profile" },
        { text: "Suivre mes commandes", href: "bellis://ArchivesCommandes" },
      ],
    },
    {
      title: "INFORMATION LÉGALES",
      links: [
        { text: "Conditions générales de Vente", href: "https://api.epiceriebellis.fr/docs/cgv.pdf" },
        { text: "Mentions légales", href: "https://api.epiceriebellis.fr/docs/mentions-legales.pdf" },
        { text: "Politique de confidentialité", href: "https://api.epiceriebellis.fr/docs/politique-de-confidentialite.pdf" },
      ],
    },
  ];

  const socialLinks = [
    {
      href: "#",
      label: "Twitter",
      icon: "https://api.epiceriebellis.fr/siteweb.bellis/assets/icons/twitter.x.png",
    },
    {
      href: "https://www.linkedin.com/company/%C3%A9picerie-belli-s/",
      label: "LinkedIn",
      icon: "https://api.epiceriebellis.fr/siteweb.bellis/assets/icons/linkedin.png",
    },
    {
      href: "https://www.instagram.com/epiceriebellis/",
      label: "Instagram",
      icon: "https://api.epiceriebellis.fr/siteweb.bellis/assets/icons/instagram.png",
    },
    {
      href: "https://www.facebook.com/profile.php?id=100089933014236",
      label: "Facebook",
      icon: "https://api.epiceriebellis.fr/siteweb.bellis/assets/icons/facebook.png",
    },
    {
      href: "https://www.youtube.com/@epiceriebellis",
      label: "Youtube",
      icon: "https://api.epiceriebellis.fr/siteweb.bellis/assets/icons/youtube.png",
    },
  ];

  const toggleSection = (title) => {
    setActiveSection(activeSection === title ? null : title);
  };

  const handleLinkClick = (href) => {
    if (href.startsWith("bellis://")) {
      // Tente d'ouvrir l'application avec le lien profond
      window.location.href = href;
  
      // Si l'application n'est pas ouverte dans un délai donné, redirige vers la page de téléchargement dans un nouvel onglet
      setTimeout(() => {
        // Crée un lien temporaire pour garantir l'ouverture dans un nouvel onglet
        const newTabLink = document.createElement("a");
        newTabLink.href = "/#/application-mobile"; // URL de la page de téléchargement
        newTabLink.target = "_blank";
        newTabLink.rel = "noopener noreferrer"; // Sécurité supplémentaire
        document.body.appendChild(newTabLink);
        newTabLink.click();
        document.body.removeChild(newTabLink); // Supprime le lien après clic
      }, 1500); // délai de 1,5 secondes pour détecter l'absence de l'application
    } else {
      window.location.href = href;
    }
  };

  return (
    <footer>
      <div className="links-section" style={{ background: theme.container }}>
        <div className="container">
          {sections.map((section) => (
            <div key={section.title} className="section">
              <button
                onClick={() => toggleSection(section.title)}
                className="section-title"
                style={{ color: theme.title }}
              >
                {section.title}
                <svg
                  className={`arrow ${activeSection === section.title ? "rotated" : ""}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <polyline points="9 18 15 12 9 6" />
                </svg>
              </button>
              <div className={`links ${activeSection === section.title ? "active" : ""}`}>
                {section.links.map((link) => (
                  <a
                    key={link.text}
                    onClick={(e) => {
                      e.preventDefault();
                      handleLinkClick(link.href);
                    }}
                    href={link.href}
                    className="link"
                    style={{ color: theme.title }}
                  >
                    {link.text}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="social-section">
        <div className="container">
          <div className="social-links">
            {socialLinks.map(({ icon, href, label }) => (
              <a key={label} href={href} className="social-link" aria-label={label}>
                <img src={icon} alt={label} className="social-icon" />
              </a>
            ))}
          </div>
          <div className="copyright" style={{ color: theme.title }}>
            <p>© 2024 Épicerie Belli's - Tous droits réservés</p>
          </div>
        </div>
      </div>


      <style jsx>{`
        footer {
        }

        .container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 1rem;
        }


        .container {
          display: grid;
          grid-template-columns: 1fr;
          gap: 2rem;
        }

        @media (min-width: 768px) {
          .container {
            grid-template-columns: repeat(2, 1fr);
          }
            
        }

       

        .value-header {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1rem;
        }

        .value-icon {
          font-size: 2rem;
        }

      

        .links-section {
          padding: 2rem 0;
        }

        .section {
          border-bottom: 1px solid #e5e7eb;
        }

        @media (min-width: 768px) {
          .section {
            border: none;
          }

          .links-section .container {
            grid-template-columns: repeat(3, 1fr);
          }
        }

        .section-title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem 0;
          font-weight: bold;
          background: none;
          border: none;
          cursor: pointer;
          text-align: left;
        }

        .arrow {
          width: 20px;
          height: 20px;
          transition: transform 0.3s ease;
        }

        .arrow.rotated {
          transform: rotate(90deg);
        }

        @media (min-width: 768px) {
          .arrow {
            display: none;
          }
        }

        .links {
          display: none;
          flex-direction: column;
          gap: 0.75rem;
          padding-bottom: 1rem;
        }

        .links.active {
          display: flex;
        }

        @media (min-width: 768px) {
          .links {
            display: flex !important;
          }
        }

        .link {
          display: flex;
          align-items: center;
          color: #4b5563;
          text-decoration: none;
          transition: all 0.3s ease;
        }

     .link {
  color: inherit; /* Permet à la couleur de texte de venir du style inline (theme.title) */
  transition: color 0.3s ease; /* Transition douce pour changer la couleur */
}

.link:hover {
  color: #00FF00; /* Couleur verte pour l'effet de survol */
}

        .social-section {
          padding: 2rem 0;
        }

        .social-links {
          display: flex;
          justify-content: center;
          gap: 1.5rem;
          margin-bottom: 1rem;
          margin-top: 1rem;
        }

        .social-link {
          display: block;
          transition: all 0.3s ease;
        }

        .social-link:hover {
          transform: scale(1.1);
        }

        .social-icon {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }

        .copyright {
          text-align: center;
          color: #6b7280;
          font-size: 0.875rem;
        }

        .tagline {
          font-size: 0.75rem;
          margin-top: 0.5rem;
        }
        .links-section {
    background-color: #fff;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
transition: all 0.3s ease;
}
.section-title {
    color: #000;
}
}
      `}</style>
    </footer>
  )
}


export const GA_TRACKING_ID = 'G-W5BBCV2NSB';

// Fonction pour détecter le type d'appareil
function getDeviceType() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
}

// Fonction pour obtenir les dimensions personnalisées
function getCustomDimensions() {
  const deviceType = getDeviceType();
  const screenSize = `${window.innerWidth}x${window.innerHeight}`;
  const orientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';

  return {
    device_type: deviceType,
    screen_size: screenSize,
    orientation: orientation,
    viewport_width: window.innerWidth,
    viewport_height: window.innerHeight
  };
}

export function initGoogleAnalytics() {
  if (typeof window === 'undefined' || window.gtag) {
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
  
  script.onerror = () => {
    console.error('Failed to load Google Analytics');
  };
  
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag;

  const customDimensions = getCustomDimensions();

  gtag('js', new Date());
  gtag('config', GA_TRACKING_ID, {
    send_page_view: true,
    page_location: window.location.href,
    page_path: window.location.pathname + window.location.search + window.location.hash,
    page_title: document.title,
    custom_map: {
      dimension1: 'device_type',
      dimension2: 'screen_size',
      dimension3: 'orientation'
    },
    ...customDimensions
  });
}

export function trackPageView(path) {
  if (typeof window === 'undefined' || !window.gtag) {
    return;
  }

  try {
    const customDimensions = getCustomDimensions();

    window.gtag('event', 'page_view', {
      page_location: window.location.href,
      page_path: path,
      page_title: document.title,
      send_to: GA_TRACKING_ID,
      ...customDimensions
    });

    // Configuration mise à jour
    window.gtag('config', GA_TRACKING_ID, {
      page_path: path,
      page_location: window.location.href,
      page_title: document.title,
      ...customDimensions
    });
  } catch (error) {
    console.error('Error tracking page view:', error);
  }
}

export function trackEvent(action, category, label, value) {
  if (typeof window === 'undefined' || !window.gtag) {
    return;
  }

  try {
    const customDimensions = getCustomDimensions();

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
      send_to: GA_TRACKING_ID,
      ...customDimensions
    });
  } catch (error) {
    console.error('Error tracking event:', error);
  }
}

// Fonction pour traquer les interactions utilisateur avec données de l'appareil
export function trackUserInteraction(interactionType, elementName) {
  if (typeof window === 'undefined' || !window.gtag) {
    return;
  }

  try {
    const customDimensions = getCustomDimensions();

    window.gtag('event', 'user_interaction', {
      event_category: interactionType,
      event_label: elementName,
      send_to: GA_TRACKING_ID,
      ...customDimensions
    });
  } catch (error) {
    console.error('Error tracking user interaction:', error);
  }
}

// Fonction pour traquer les changements de taille d'écran
export function trackScreenResize() {
  if (typeof window === 'undefined') return;

  let resizeTimeout;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      const customDimensions = getCustomDimensions();
      window.gtag('event', 'screen_resize', {
        event_category: 'Device',
        event_label: `${customDimensions.screen_size} - ${customDimensions.orientation}`,
        send_to: GA_TRACKING_ID,
        ...customDimensions
      });
    }, 500);
  });
}
import React, { useState, useEffect, useContext } from 'react';
import { X, Bell, MessageSquare, Star, PartyPopper, Tag } from 'lucide-react';
import styles from '../css/Popup.module.css';
import { ThemeContext } from './ThemeContext'; // Assurez-vous d'importer correctement ThemeContext

function RotatingPopup({ onClose }) {
  const [isVisible, setIsVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [changing, setChanging] = useState(false);
  const { theme } = useContext(ThemeContext); // Utilisation du thème

  const notifications = [
    {
      Icon: PartyPopper,
      title: "Application disponible",
      message: "Ouverture de l'application début mars 2025",
    },
    {
        Icon: Tag,
        title: "Offre de bienvenue",
        message: "5% offerts sur la première commande automatiquement",
      },
    /*{
      Icon: Star,
      title: "Nouveau favori",
      message: "Un élément a été ajouté aux favoris",
    },*/
  ];

  const backgroundColors = ["#D1FAE5", "#FEF3C7", "#E5E7EB"]; // Couleurs de fond pour chaque notification
  const iconColors = ["#004D4D", "#B45309", "#6B7280"]; // Couleurs des icônes

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const interval = setInterval(() => {
      setChanging(true);
      setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % notifications.length);
        setChanging(false);
      }, 300);
    }, 5000);

    return () => clearInterval(interval);
  }, [isVisible, notifications.length]);

  if (!isVisible) return null;

  const { Icon, title, message } = notifications[currentIndex];
  const backgroundColor = backgroundColors[currentIndex % backgroundColors.length]; // Couleur du fond du carré
  const iconColor = iconColors[currentIndex % iconColors.length]; // Couleur de l'icône

  return (
    <div className={styles.popupContainer} style={{ background: theme.container }}>
      <div className={styles.popup} style={{ borderColor: backgroundColor, background: theme.container  }} >
        <div
          className={`${styles.popupIcon} ${changing ? styles.changing : ''}`}
          style={{ backgroundColor: backgroundColor }} 
        >
          <Icon className={styles.icon} style={{ color: iconColor }} /> {/* Couleur de l'icône */}
        </div>
        <div className={styles.popupContent}>
          <div className={`${styles.popupText} ${changing ? styles.changing : ''}`}>
            <h4 style={{ color: theme.title }}>{title}</h4>
            <p style={{ color: theme.title }}>{message}</p>
          </div>
        </div>
        <button
          onClick={() => {
            setIsVisible(false);
            if (onClose) onClose();
          }}
          className={styles.popupClose} style={{ color: theme.title }}
        >
          <X size={20} />
        </button>
      </div>
    </div>
  );
}

export default RotatingPopup;
import React, { createContext, useState, useEffect } from 'react';

// Thèmes pour les modes clair et sombre
const lightTheme = {
  mode: 'light',
  background: '#F0F0F2',
  title: '#000',
  text: '#6b7280',
  container: '#FFF',
  borderlogo: '#FFF',
  borderlogoOrder: '#F5F5F7',
  colorgrey: 'grey',
  overlayContainer: 'rgba(245, 245, 247, 0.5)',
  themeColor: '#F5F5F7', // Couleur pour le tag meta en mode clair
  hoverColor: '#FF6347', // Couleur de survol pour le mode clair
};

const darkTheme = {
  mode: 'dark',
  background: '#1d1b1e',
  title: '#fff',
  text: '#6b7280',
  container: '#3c3b42',
  borderlogo: '#3c3b42',
  borderlogoOrder: '#1d1b1e',
  colorgrey: '#fff',
  overlayContainer: 'rgba(29, 27, 30, 0.5)',
  themeColor: '#1d1b1e', // Couleur pour le tag meta en mode sombre
  hoverColor: '#FF6347', // Couleur de survol pour le mode clair

};

// Création du contexte
export const ThemeContext = createContext();

// Définition du fournisseur de thème
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      ? darkTheme
      : lightTheme
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (e) => {
      setTheme(e.matches ? darkTheme : lightTheme);
    };

    // Ajouter ou changer la couleur du tag meta `theme-color` en fonction du thème
    const updateThemeColor = () => {
      const themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute('content', theme.themeColor);
      } else {
        const meta = document.createElement('meta');
        meta.name = 'theme-color';
        meta.content = theme.themeColor;
        document.head.appendChild(meta);
      }
    };

    // Ajouter ou changer la classe ou l'attribut `data-theme` sur l'élément <html>
    const updateHtmlTheme = () => {
      const htmlElement = document.documentElement; // L'élément <html>
      if (theme.mode === 'dark') {
        htmlElement.setAttribute('data-theme', 'dark'); // Ajoute `data-theme="dark"` à <html>
        htmlElement.classList.add('dark'); // Ajoute la classe `dark` à <html>
        htmlElement.classList.remove('light');
      } else {
        htmlElement.setAttribute('data-theme', 'light'); // Ajoute `data-theme="light"` à <html>
        htmlElement.classList.add('light'); // Ajoute la classe `light` à <html>
        htmlElement.classList.remove('dark');
      }
    };

    // Initialiser le thème
    updateThemeColor();
    updateHtmlTheme();

    // Surveiller les changements de mode
    mediaQuery.addEventListener('change', handleChange);

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme }}>
    <div style={{ '--hover-color': theme.hoverColor }}>
      {children}
    </div>
  </ThemeContext.Provider>
  );
};